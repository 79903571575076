import * as React from 'react'
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import { NextPage, NextPageContext } from 'next'
import { AppContext } from 'next/app'
import { DocumentContext } from 'next/document'

import { EnterpriseThemeInterface } from '@thg-commerce/enterprise-theme'

import { Configuration } from '../ConfigurationLoader/types'
import { Logger } from '../Logger/types'
import { EnterpriseRequest, EnterpriseResponse } from '../Server/types'

export interface EnterpriseNextPageContext extends NextPageContext {
  req: EnterpriseRequest
  res: EnterpriseResponse
  logger: Logger
  config: Configuration
  apolloClient: ApolloClient<NormalizedCacheObject>
  theme?: EnterpriseThemeInterface
}
export interface EnterpriseNextAppContext extends AppContext {
  ctx: EnterpriseNextPageContext
}

export enum PageType {
  HOME_PAGE = 'HOMEPAGE',
  LANDING = 'LANDING',
  SEARCH = 'SEARCH',
  PRODUCT = 'PRODUCT',
  ACCOUNT = 'ACCOUNT',
  BASKET = 'BASKET',
  PRODUCT_LIST = 'PRODUCT_LIST',
  STORE_LOCATOR = 'STORE_LOCATOR',
  PERSONALISE = 'PERSONALISE_YOUR_BOX',
}

export type EnterpriseNextDocumentContext = EnterpriseNextPageContext &
  DocumentContext

export interface EnterpriseNextPageProperties<ComponentProps> {
  Layout: React.ComponentType<{ children: React.ReactNode }>
  useAmpLayout?: boolean
  getConcessionCode?: (
    ctx: EnterpriseNextPageContext,
    apolloClient?: ApolloClient<NormalizedCacheObject>,
  ) => Promise<string | undefined>
  supportsConcessions?: boolean
  pageType?:
    | ((
        requestUrl: string | undefined,
        componentProps: ComponentProps,
      ) => PageType | undefined)
    | PageType
}

export type EnterpriseNextPage<Props = {}, InitialProps = Props> = NextPage<
  Props,
  InitialProps
> &
  EnterpriseNextPageProperties<Props & InitialProps>
