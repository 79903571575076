import React, { useCallback, useEffect, useMemo, useState } from 'react'
import loadable from '@loadable/component'

import { CombinedThemeInterface } from '@thg-commerce/gravity-patterns'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'

import {
  CountdownTextWrapper,
  CountdownWrapper,
  IndicatorContainer,
  IndicatorDot,
  NoLinkContainer,
  Panel,
  StyledHref,
  StyledSafeHtml,
  StyledSVGWrapper,
  USPBarWrapper,
} from '../styles'
import type { AlternateIcons, USPBarPanel, USPBarQueryResult } from '../types'

const Time = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Time'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Coins = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/RewardPoints'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const InternationalDelivery = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/InternationalDelivery'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Checkmark = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Checkmark'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Calender = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Calender'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const ContentEmail = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ContentEmail'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Truck = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Truck'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Smartphone = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Smartphone'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const TelephoneUsp = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/TelephoneUsp'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Clipboard = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Clipboard'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const ClickAndCollect = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ClickAndCollect'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const StoreLocator = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/StoreLocator'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const ZipGrey = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ZipGrey'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const AccountReturns = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/AccountReturns'),
)

const PrefetchStyledHref = withPrefetch('href', StyledHref)

export const timingPollResolution = 200

const formatNumber = (number: number) => {
  return `0${number}`.slice(-2)
}

export const numberToDay = (dayAsNumber: number): string => {
  switch (dayAsNumber) {
    case 0:
      return 'sunday'
    case 1:
      return 'monday'
    case 2:
      return 'tuesday'
    case 3:
      return 'wednesday'
    case 4:
      return 'thursday'
    case 5:
      return 'friday'
    case 6:
      return 'saturday'
    default:
      return 'thursday'
  }
}

const CountDownText = (props: {
  rawPanelInformation: USPBarQueryResult
  isDesktop: boolean
}) => {
  const { rawPanelInformation, isDesktop } = props
  const { timezoneOffset } = rawPanelInformation

  const [workingDay, setDay] = useState(new Date().getDay())
  const [countdownTarget, updateCountdownTarget] = useState(
    rawPanelInformation[`${numberToDay(workingDay)}Cutoff`],
  )
  const [secondWatcher, updateSeconds] = useState((Date.now() * 1000) % 60)

  useEffect(() => {
    const tickFrame = setInterval(tick, timingPollResolution / 5)

    return () => {
      clearInterval(tickFrame)
    }
  })

  const tick = useCallback(() => {
    const currentDay = new Date().getDay()
    const dayChanged = currentDay !== workingDay

    if (dayChanged) {
      setDay(currentDay)
      updateCountdownTarget(
        rawPanelInformation[`${numberToDay(workingDay)}Cutoff`],
      )
    }

    const currentSeconds = (Date.now() * 1000) % 60
    if (secondWatcher !== currentSeconds) updateSeconds(currentSeconds)
  }, [secondWatcher, rawPanelInformation, workingDay])

  const finishedCountdown = useMemo(() => {
    return (
      <CountdownTextWrapper
        data-testid="countdown-finished"
        key="countdown-text-empty"
      >
        {rawPanelInformation.countDownText}
      </CountdownTextWrapper>
    )
  }, [rawPanelInformation])

  const timeRegex = /^(\d|\d\d):(\d\d)$/
  const hoursAndMinutes = timeRegex.exec(countdownTarget)

  if (!hoursAndMinutes) {
    return finishedCountdown
  }

  const targetHour =
    parseInt(hoursAndMinutes[1], 10) - parseInt(timezoneOffset, 10)
  const targetMinute = parseInt(hoursAndMinutes[2], 10)

  if (
    isNaN(targetHour) ||
    isNaN(targetMinute) ||
    targetHour < 0 ||
    targetHour > 23 ||
    targetMinute < 0 ||
    targetMinute > 59
  ) {
    return finishedCountdown
  }

  const currentTime = new Date()
  const targetTime = new Date()
  const timeToCountdown =
    targetTime.setHours(targetHour, targetMinute, 0) - currentTime.getTime()

  if (timeToCountdown < 0) {
    return finishedCountdown
  }

  const hours = formatNumber(Math.floor((timeToCountdown / 3600 / 1000) % 24))
  const minutes = formatNumber(Math.floor((timeToCountdown / 60 / 1000) % 60))
  const seconds = formatNumber(Math.floor((timeToCountdown / 1000) % 60))

  return (
    <CountdownTextWrapper
      data-testid={`countdown-${hours}:${minutes}:${seconds}`}
      key="countdown-text"
    >
      {rawPanelInformation.countDownText}:
      <CountdownWrapper key="wrapper" isDesktop={isDesktop}>
        {hours}:{minutes}:{seconds}
      </CountdownWrapper>
    </CountdownTextWrapper>
  )
}

export const generatePanels = (
  rawPanelInformation: USPBarQueryResult | undefined,
  isDesktop: boolean,
  theme: CombinedThemeInterface,
  useAlternateIcon: AlternateIcons,
): USPBarPanel[] => {
  const panels: USPBarPanel[] = []

  if (!rawPanelInformation) {
    return []
  }

  Object.keys(rawPanelInformation).forEach((dataField) => {
    if (/.*Text/.exec(dataField) && rawPanelInformation[dataField] !== '') {
      switch (dataField) {
        case 'rewardPointsText':
          pushToPanels(
            panels,
            {
              child: (
                <StyledSafeHtml
                  data-testid="rewards-points-text"
                  content={rawPanelInformation[dataField]}
                  removeTagsAndContent={false}
                  tagsToRemove={[]}
                />
              ),
              link: rawPanelInformation.rewardPointsLink,
              svg:
                theme.widgets.uspBar.icon.rewards.svgPath !== '' ? (
                  <SvgIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={theme.widgets.uspBar.icon.viewBox}
                    width={theme.widgets.uspBar.icon.width}
                    height={theme.widgets.uspBar.icon.height}
                  >
                    <path
                      d={theme.widgets.uspBar.icon.rewards.svgPath}
                      fillRule="evenodd"
                    />
                  </SvgIcon>
                ) : (
                  <Coins
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ),
              key: dataField,
              order: theme.widgets.uspBar.icon.rewards.order,
            },
            theme,
          )
          break
        case 'countDownText':
          pushToPanels(
            panels,
            {
              child: (
                <CountDownText
                  rawPanelInformation={rawPanelInformation}
                  isDesktop={isDesktop}
                />
              ),
              link: rawPanelInformation.countDownLink,
              svg:
                theme.widgets.uspBar.icon.countDown.svgPath !== '' ? (
                  <SvgIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={theme.widgets.uspBar.icon.viewBox}
                    width={theme.widgets.uspBar.icon.width}
                    height={theme.widgets.uspBar.icon.height}
                  >
                    <path
                      d={theme.widgets.uspBar.icon.countDown.svgPath}
                      fillRule="evenodd"
                    />
                  </SvgIcon>
                ) : (
                  <Time
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ),
              key: dataField,
              order: theme.widgets.uspBar.icon.countDown.order,
              useStrokeFill: true,
            },
            theme,
          )
          break
        case 'internationalDeliveryText':
          pushToPanels(
            panels,
            {
              child: (
                <StyledSafeHtml
                  data-testid="international-delivery-text"
                  content={rawPanelInformation[dataField]}
                  removeTagsAndContent={false}
                  tagsToRemove={[]}
                />
              ),
              link: rawPanelInformation.internationalDeliveryLink,
              svg:
                theme.widgets.uspBar.icon.internationalDelivery.svgPath !==
                '' ? (
                  <SvgIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={theme.widgets.uspBar.icon.viewBox}
                    width={theme.widgets.uspBar.icon.width}
                    height={theme.widgets.uspBar.icon.height}
                  >
                    <path
                      d={
                        theme.widgets.uspBar.icon.internationalDelivery.svgPath
                      }
                      fillRule="evenodd"
                    />
                  </SvgIcon>
                ) : (
                  <InternationalDelivery
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ),
              key: dataField,
              order: theme.widgets.uspBar.icon.internationalDelivery.order,
            },
            theme,
          )
          break
        case 'checkmarkText':
          pushToPanels(
            panels,
            {
              child: (
                <StyledSafeHtml
                  data-testid="check-text"
                  content={rawPanelInformation[dataField]}
                  removeTagsAndContent={false}
                  tagsToRemove={[]}
                />
              ),
              link: rawPanelInformation.checkmarkLink,
              svg:
                theme.widgets.uspBar.icon.checkmark.svgPath !== '' ? (
                  <SvgIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={theme.widgets.uspBar.icon.viewBox}
                    width={theme.widgets.uspBar.icon.width}
                    height={theme.widgets.uspBar.icon.height}
                  >
                    <path
                      d={theme.widgets.uspBar.icon.checkmark.svgPath}
                      fillRule="evenodd"
                    />
                  </SvgIcon>
                ) : (
                  <Checkmark
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ),
              key: dataField,
              order: theme.widgets.uspBar.icon.checkmark.order,
              useStrokeFill: true,
            },
            theme,
          )
          break
        case 'calendarText':
          pushToPanels(
            panels,
            {
              child: (
                <StyledSafeHtml
                  data-testid="calender-text"
                  content={rawPanelInformation[dataField]}
                  removeTagsAndContent={false}
                  tagsToRemove={[]}
                />
              ),
              link: rawPanelInformation.calendarLink,
              svg:
                theme.widgets.uspBar.icon.calender.svgPath !== '' ? (
                  <SvgIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={theme.widgets.uspBar.icon.viewBox}
                    width={theme.widgets.uspBar.icon.width}
                    height={theme.widgets.uspBar.icon.height}
                  >
                    <path
                      d={theme.widgets.uspBar.icon.calender.svgPath}
                      fillRule="evenodd"
                    />
                  </SvgIcon>
                ) : (
                  <Calender
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ),
              key: dataField,
              order: theme.widgets.uspBar.icon.calender.order,
              useStrokeFill: false,
            },
            theme,
          )
          break
        case 'emailText':
          pushToPanels(
            panels,
            {
              child: (
                <StyledSafeHtml
                  data-testid="email-text"
                  content={rawPanelInformation[dataField]}
                  removeTagsAndContent={false}
                  tagsToRemove={[]}
                />
              ),
              link: rawPanelInformation.emailLink,
              svg:
                theme.widgets.uspBar.icon.email.svgPath !== '' ? (
                  <SvgIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={theme.widgets.uspBar.icon.viewBox}
                    width={theme.widgets.uspBar.icon.width}
                    height={theme.widgets.uspBar.icon.height}
                  >
                    <path
                      d={theme.widgets.uspBar.icon.email.svgPath}
                      fillRule="evenodd"
                    />
                  </SvgIcon>
                ) : (
                  <ContentEmail
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ),
              key: dataField,
              order: theme.widgets.uspBar.icon.email.order,
            },
            theme,
          )
          break
        case 'deliveryText':
          pushToPanels(
            panels,
            {
              child: (
                <StyledSafeHtml
                  data-testid="delivery-text"
                  content={rawPanelInformation[dataField]}
                  removeTagsAndContent={false}
                  tagsToRemove={[]}
                />
              ),
              link: rawPanelInformation.deliveryLink,
              svg:
                theme.widgets.uspBar.icon.delivery.svgPath !== '' ? (
                  <SvgIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={theme.widgets.uspBar.icon.viewBox}
                    width={theme.widgets.uspBar.icon.width}
                    height={theme.widgets.uspBar.icon.height}
                  >
                    <path
                      d={theme.widgets.uspBar.icon.delivery.svgPath}
                      fillRule="evenodd"
                    />
                  </SvgIcon>
                ) : (
                  <Truck
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ),
              key: dataField,
              order: theme.widgets.uspBar.icon.delivery.order,
            },
            theme,
          )
          break
        case 'callText':
          pushToPanels(
            panels,
            {
              child: (
                <StyledSafeHtml
                  data-testid="call-text"
                  content={rawPanelInformation[dataField]}
                  removeTagsAndContent={false}
                  tagsToRemove={[]}
                />
              ),
              link: rawPanelInformation.callLink,
              icon: rawPanelInformation.callIcon,
              svg:
                theme.widgets.uspBar.icon.call.svgPath !== '' ? (
                  <SvgIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={theme.widgets.uspBar.icon.viewBox}
                    width={theme.widgets.uspBar.icon.width}
                    height={theme.widgets.uspBar.icon.height}
                  >
                    <path
                      d={theme.widgets.uspBar.icon.call.svgPath}
                      fillRule="evenodd"
                    />
                  </SvgIcon>
                ) : rawPanelInformation.callIcon === 'mobile' ? (
                  <Smartphone
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ) : (
                  <TelephoneUsp
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ),
              key: dataField,
              order: theme.widgets.uspBar.icon.call.order,
            },
            theme,
          )
          break
        case 'qualityText':
          pushToPanels(
            panels,
            {
              child: (
                <StyledSafeHtml
                  data-testid="quality-text"
                  content={rawPanelInformation[dataField]}
                  removeTagsAndContent={false}
                  tagsToRemove={[]}
                />
              ),
              link: rawPanelInformation.qualityLink,
              svg:
                theme.widgets.uspBar.icon.quality.svgPath !== '' ? (
                  <SvgIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={theme.widgets.uspBar.icon.viewBox}
                    width={theme.widgets.uspBar.icon.width}
                    height={theme.widgets.uspBar.icon.height}
                  >
                    <path
                      d={theme.widgets.uspBar.icon.quality.svgPath}
                      fillRule="evenodd"
                    />
                  </SvgIcon>
                ) : useAlternateIcon.qualityText ? (
                  <ZipGrey
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ) : (
                  <Clipboard
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ),
              key: dataField,
              order: theme.widgets.uspBar.icon.quality.order,
              testId: useAlternateIcon.qualityText
                ? 'zip-pay-icon'
                : 'quality-clipboard-svg',
              useStrokeFill: useAlternateIcon.qualityText ? false : true,
              isClipBoard: useAlternateIcon.qualityText ? false : true,
            },
            theme,
          )
          break
        case 'clickAndCollectText':
          pushToPanels(
            panels,
            {
              child: (
                <StyledSafeHtml
                  data-testid="click-collect-text"
                  content={rawPanelInformation[dataField]}
                  removeTagsAndContent={false}
                  tagsToRemove={[]}
                />
              ),
              link: rawPanelInformation.clickAndCollectLink,
              svg:
                theme.widgets.uspBar.icon.clickAndCollect.svgPath !== '' ? (
                  <SvgIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={theme.widgets.uspBar.icon.viewBox}
                    width={theme.widgets.uspBar.icon.width}
                    height={theme.widgets.uspBar.icon.height}
                  >
                    <path
                      d={theme.widgets.uspBar.icon.clickAndCollect.svgPath}
                      fillRule="evenodd"
                    />
                  </SvgIcon>
                ) : (
                  <ClickAndCollect
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ),
              key: dataField,
              order: theme.widgets.uspBar.icon.clickAndCollect.order,
              useStrokeFill: false,
            },
            theme,
          )
          break
        case 'storeText':
          pushToPanels(
            panels,
            {
              child: (
                <StyledSafeHtml
                  data-testid="store-text"
                  content={rawPanelInformation[dataField]}
                  removeTagsAndContent={false}
                  tagsToRemove={[]}
                />
              ),
              link: rawPanelInformation.storeLink,
              svg:
                theme.widgets.uspBar.icon.store.svgPath !== '' ? (
                  <SvgIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={theme.widgets.uspBar.icon.viewBox}
                    width={theme.widgets.uspBar.icon.width}
                    height={theme.widgets.uspBar.icon.height}
                  >
                    <path
                      d={theme.widgets.uspBar.icon.store.svgPath}
                      fillRule="evenodd"
                    />
                  </SvgIcon>
                ) : useAlternateIcon.storeText ? (
                  <StoreLocator
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ) : (
                  <Clipboard
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ),
              key: dataField,
              order: theme.widgets.uspBar.icon.store.order,
              useStrokeFill: useAlternateIcon.storeText ? false : true,
              isClipBoard: useAlternateIcon.qualityText ? false : true,
            },
            theme,
          )
          break
        case 'returnsText':
          pushToPanels(
            panels,
            {
              child: (
                <StyledSafeHtml
                  data-testid="returns-text"
                  content={rawPanelInformation[dataField]}
                  removeTagsAndContent={false}
                  tagsToRemove={[]}
                />
              ),
              link: rawPanelInformation.returnsLink,
              svg:
                theme.widgets.uspBar.icon.returns.svgPath !== '' ? (
                  <SvgIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={theme.widgets.uspBar.icon.viewBox}
                    width={theme.widgets.uspBar.icon.width}
                    height={theme.widgets.uspBar.icon.height}
                  >
                    <path
                      d={theme.widgets.uspBar.icon.returns.svgPath}
                      fillRule="evenodd"
                    />
                  </SvgIcon>
                ) : (
                  <AccountReturns
                    fill={
                      theme.widgets.uspBar.iconFill ||
                      theme.colors.palette.greys.dark
                    }
                    aria-hidden="true"
                    focusable="false"
                  />
                ),
              key: dataField,
              order: theme.widgets.uspBar.icon.returns.order,
            },
            theme,
          )
          break
      }
    }
  })
  panels.sort((a, b) => a.order - b.order)
  return panels
}

function pushToPanels(
  panels: USPBarPanel[],
  panel: USPBarPanel,
  theme: CombinedThemeInterface,
) {
  if (!panel.svg) {
    panel.svg = (
      <Clipboard
        fill={theme.widgets.uspBar.iconFill || theme.colors.palette.greys.dark}
      />
    )
    panel.isClipBoard = true
  }

  if (panel.link !== '') {
    panels.push(panel)
  } else {
    delete panel.link
    panels.push(panel)
  }
}

export const Panels = (props: { panels: USPBarPanel[] }) => {
  const { panels } = props
  return (
    <USPBarWrapper>
      <div
        style={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {panels.map((panel, index) => (
          <Panel
            data-testid="usp-desktop-panel"
            key={panel.key}
            panelCount={panels.length}
            index={index}
          >
            {panel.link ? (
              <PrefetchStyledHref href={panel.link}>
                <StyledSVGWrapper
                  isClipBoard={panel.isClipBoard}
                  useStrokeFill={panel.useStrokeFill}
                  data-testid={panel.testId}
                >
                  {panel.svg}
                </StyledSVGWrapper>
                {panel.child}
              </PrefetchStyledHref>
            ) : (
              <NoLinkContainer>
                <StyledSVGWrapper
                  isClipBoard={panel.isClipBoard}
                  useStrokeFill={panel.useStrokeFill}
                >
                  {panel.svg}
                </StyledSVGWrapper>
                {panel.child}
              </NoLinkContainer>
            )}
          </Panel>
        ))}
      </div>
      <IndicatorContainer>
        {Array(panels.length)
          .fill(null)
          .map((_, index) => (
            <IndicatorDot index={index} panelCount={panels.length} />
          ))}
      </IndicatorContainer>
    </USPBarWrapper>
  )
}
