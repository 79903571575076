import * as React from 'react'

import { useTheme } from '@thg-commerce/gravity-patterns/theme'

import { generatePanels, Panels } from './Panels'
import { EmptyPanel, USPBarContainer } from './styles'
import type { AlternateIcons, USPBarQueryResult } from './types'

export interface USPBarProps {
  isDesktop: boolean
  uspBarData: USPBarQueryResult | undefined
  interval?: number
  userAgent: string
  useAlternateIcon: AlternateIcons
}

export const USPBar = (props: USPBarProps) => {
  const { isDesktop, uspBarData, useAlternateIcon } = props
  const theme = useTheme()
  const panels = React.useMemo(() => {
    return generatePanels(uspBarData, isDesktop, theme, useAlternateIcon)
  }, [uspBarData, isDesktop, theme, useAlternateIcon])

  if (panels.length === 0) {
    return (
      <USPBarContainer>
        <EmptyPanel data-testid="empty-panel" />
      </USPBarContainer>
    )
  }

  return (
    <USPBarContainer
      backgroundColour={uspBarData?.backgroundColor}
      role="complementary"
      data-testid="usp-banner"
    >
      <Panels panels={panels} />
    </USPBarContainer>
  )
}
